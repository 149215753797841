import React, { useContext } from 'react'
import { Layout, SEO, HeroHeading, Paragraph, Section, Button } from '@components'
import { Container } from '@bootstrap-styled/v4'
import { useLabels } from '@hooks'
import { ThemeContext } from 'styled-components'
import { Page } from '@types'

const NotFoundPage: React.FC<Page<unknown>> = ({ location }) => {
  const labels = useLabels()
  const { transitionDurations } = useContext(ThemeContext)

  return (
    <Layout>
      <SEO title={labels.notFound} pageUrl={location.pathname} />
      <Section hasBg fullPage isFirst vCenter>
        <Container data-sal="slide-up" data-sal-duration={transitionDurations.sal}>
          <HeroHeading>{labels.notFound}</HeroHeading>
          <Paragraph>{labels.notFoundDescription}</Paragraph>
          <Button to="/" size="lg">
            {labels.backToHomepage}
          </Button>
        </Container>
      </Section>
    </Layout>
  )
}

export default NotFoundPage
